<template>
  <section class="hero is-small is-info" scrollId="services">
    <div class="hero-body">
      <p class="title">
        Services
      </p>
      <div class="subtitle ml-2">
        Save up to 30% vs our competitors, and 50% vs dealers
        <div class="is-size-7">based on estimated average price</div>
      </div>
    </div>
  </section>
  <section>
    <div class="container p-4">
      <div class="columns is-multiline">
        <div class="column is-4 is-flex" v-for="(product, index) in products" :key="index">
          <div class="card is-flex is-flex-direction-column">
            <header class="card-header">
              <p class="card-header-title">
                {{ product.header }}
              </p>
            </header>
            <div class="card-image p-3" :scrollId="product.header.replace(/\s/g, '')">
              <figure class="image is-128x128" style="margin:0 auto;">
                <img :src="product.image" :alt="product.alt" :title="product.description">
              </figure>
            </div>
            <div class="card-content is-flex-grow-1 is-flex"> <!--  -->
              <div class="content is-flex has-text-centered is-align-items-center">
                {{ product.description }}
              </div>
            </div>
            <footer class="card-footer is-flex-grow-0 has-text-weight-bold"></footer>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import thermokingPicture from '../assets/thermoking-400.png'
import carrierPicture from '../assets/carrier-400.png'
import inspectionIcon from '../assets/icons/inspection.svg'
import repairIcon from '../assets/icons/repair.svg'
import warrantyIcon from '../assets/icons/warranty.svg'
import diagnosticsIcon from '../assets/icons/diagnostics.svg'

export default {
  name: 'Services',
  setup: function () {
    const products = [
      {
        header: 'Thermo King Service',
        image: thermokingPicture,
        description: 'Our technicians have extensive dealer training servicing Thermo King products',
        alt: 'A photo of a Thermo King refrigeration unit'
      },
      {
        header: 'Carrier Service',
        image: carrierPicture,
        description: 'Our technicians have professional training servicing Carrier products',
        alt: 'A photo of a Carrier refrigeration unit'
      },
      {
        header: 'Inspection & Maintenance',
        image: inspectionIcon,
        description: 'Our technicians perform on-site routine inspections and maintenance to keep your trucks moving on the road, and not stopped on the side.',
        alt: 'A clipboard with a magnifying glass icon'
      },
      {
        header: 'Reefer Repair',
        image: repairIcon,
        description: 'In the event of a break down, our technicians are ready, and prepared, to fix any issue on site.',
        alt: 'A wrench and screwdriver icon'
      },
      {
        header: 'Our Warranty',
        image: warrantyIcon,
        description: 'All of our services come with a warranty you would expect from a dealer, but at a fraction of the cost.',
        alt: 'A piece of paper with writing icon'
      },
      {
        header: 'Computer Diagnostics and Updates',
        image: diagnosticsIcon,
        description: 'We offer trip temperature recordings analysis, as well as provide software updates when required.',
        alt: 'A laptop computer with a health cross icon on the screen'
      }
    ]

    return {
      products
    }
  }
}
</script>

<style scoped>
  .sameheight {
    display: flex;
  }

  .image > img {
    fill: #2c3e50;
    height: 128px;
    width: 128px;
  }
</style>
