<template>
  <div class="columns is-multiline is-centered">
    <div class="column is-12-mobile is-12-tablet is-10-desktop is-9-widescreen is-7-fullhd is-centered pt-0">
      <TopNav></TopNav>
      <Pictures></Pictures>
      <WhyChoose></WhyChoose>
      <Services></Services>
      <CustomerLoyalty></CustomerLoyalty>
      <ContactUs></ContactUs>
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import TopNav from './components/TopNav.vue'
import Pictures from './components/Pictures.vue'
import WhyChoose from './components/WhyChoose.vue'
import Services from './components/Services.vue'
import CustomerLoyalty from './components/CustomerLoyalty.vue'
import ContactUs from './components/ContactUs.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'Home',
  components: {
    TopNav,
    Pictures,
    WhyChoose,
    Services,
    CustomerLoyalty,
    ContactUs,
    Footer
  }
}
</script>

<style>
#app {
  /*font-family: Avenir, Helvetica, Arial, sans-serif;*/
  font-family: 'Noto Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #2c3e50;
}

.modal {
  width: 100vw;
  padding:10px;
}
</style>
