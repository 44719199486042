<template>
  <section class="hero is-small is-info" scrollId="CustomerLoyaltyProgram">
    <div class="hero-body">
      <p class="title">
        Customer Loyalty Programs
      </p>
      <div class="subtitle ml-2">
        Continued business brings huge savings!
      </div>
    </div>
  </section>
  <section>
    <div class="container p-4">
      <div class="columns is-multiline">
        <div class="column is-6 is-flex" v-for="(product, index) in products" :key="index">
          <div class="card is-flex is-flex-direction-column">
            <header class="card-header">
              <p class="card-header-title">
                {{ product.header }}
              </p>
            </header>
            <div class="card-image p-3" :scrollId="product.header.replace(/\s/g, '')">
              <figure class="image is-128x128" style="margin:0 auto;">
                <img :src="product.image" :alt="product.alt" :title="product.description">
              </figure>
            </div>
            <div class="card-content is-flex-grow-1 is-flex"> <!--  -->
              <div class="content is-flex has-text-centered is-align-items-center">
                {{ product.description }}
              </div>
            </div>
            <footer class="card-footer is-flex-grow-0 has-text-weight-bold"></footer>
          </div>
        </div>
        <div class="column is-12 is-size-7 has-text-centered">
          *Some conditions apply.
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import loyaltyIcon from '../assets/icons/loyalty.svg'
import referralIcon from '../assets/icons/referral.svg'

export default {
  name: 'CustomerLoyalty',
  setup: function () {
    const products = [
      {
        header: 'Regular Service Savings',
        image: loyaltyIcon,
        description: 'Continued regular maintenance with Day and Night includes held labour costs, and frozen part prices. Also, every 10th service is free!*',
        alt: 'A black and white icon showing a briefcase and clock'
      },
      {
        header: 'Customer Referral Program',
        image: referralIcon,
        description: 'Refer Day and Night a customer with a similar sized (or larger) fleet as your own, and get yourself 15% off our services!*',
        alt: 'A black and white icon showing 2 people outlines with a double sided arrow between them.'
      }
      // ,
      // {
      //   header: 'Carrier Service',
      //   image: carrierPicture,
      //   description: 'Our technicians have professional training servicing Carrier products',
      //   alt: 'A photo of a Carrier refrigeration unit',
      //   savings: 30
      // },
      // {
      //   header: 'Inspection & Maintenance',
      //   image: inspectionIcon,
      //   description: 'Our technicians perform on-site routine inspections and maintenance to keep your trucks moving on the road, and not stopped on the side.',
      //   alt: 'A clipboard with a maginifying glass icon',
      //   savings: 30
      // },
      // {
      //   header: 'Reefer Repair',
      //   image: repairIcon,
      //   description: 'In the event of a break down, our technicians are ready, and prepared, to fix any issue on site.',
      //   alt: 'A wrench and screwdriver icon',
      //   savings: 30
      // },
      // {
      //   header: 'Our Warranty',
      //   image: warrantyIcon,
      //   description: 'All of our services come with a warranty you would expect from a dealer, but at a fraction of the cost.',
      //   alt: 'A piece of paper with writing icon',
      //   savings: 30
      // },
      // {
      //   header: 'Customer Loyalty Program',
      //   image: loyaltyIcon,
      //   description: 'Continued regular maintenance with Day and Night includes held labour costs, and frozen part prices. Also, every 10th service is free! Think of the savings...',
      //   alt: 'A piece of paper with writing icon',
      //   savings: 30
      // }
    ]

    return {
      products
    }
  }
}
</script>

<style scoped>
  .sameheight {
    display: flex;
  }

  .image > img {
    fill: #2c3e50;
    height: 128px;
    width: 128px;
  }
</style>
