<template>
  <div class="p-4 is-size-7">
    <div class="content has-text-centered">
      <p>
          © {{ new Date().getFullYear() }} Day and Night Transport Refrigeration Inc. All Rights Reserved. | Icons by <a href="https://fontawesome.com/">Font Awesome</a>.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style scoped>

</style>
