<template>
  <section class="hero is-small is-info" scrollId="contactUs">
    <div class="hero-body">
      <p class="title">
        Contact Us
      </p>
      <div class="subtitle ml-2 is-size-5">
        We look forward to doing business with you
      </div>
      <div class="subtitle ml-2">
        <div class="buttons">
          <div class="button m-1" v-for="(button,index) in buttons" :key="index" @click="button.onclick">
            <span class="icon">
              <img :src="button.icon" />
            </span>
            <span :class="['rev', button.class]"></span>
          </div>
        </div>
      </div>
      <div class="subtitle ml-2">
        <table class="table has-text-centered">
          <thead>
            <tr>
              <td colspan="3">Business Hours</td>
          </tr></thead>
          <tbody>
            <tr>
              <td>Monday</td>
              <td>-</td>
              <td>Friday</td>
            </tr>
            <tr>
              <td>9:00AM</td>
              <td>-</td>
              <td>5:00PM</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </section>
</template>

<script>
import phoneIcon from '../assets/icons/phone.svg'
import emailIcon from '../assets/icons/email.svg'
// import googleIcon from '../assets/icons/google-brands.svg'

export default {
  name: 'ContactUs',
  setup: function () {
    const buttons = [
      {
        class: 'phone',
        icon: phoneIcon,
        onclick: function () {
          window.open('tel:+14162008743')
        }
      },
      {
        class: 'email',
        icon: emailIcon,
        onclick: function () {
          window.open('mailto:andy@dayandnightrefrigeration.com')
        }
      }
      // {
      //   text: 'Google',
      //   icon: googleIcon,
      //   onclick: function () {
      //     window.open('https://google.com')
      //   }
      // }
    ]
    return {
      buttons
    }
  }
}
</script>

<style scoped>
  .icon > img {
    height: 18px;
    width: 18px;
  }

  span.rev {
    unicode-bidi: bidi-override;
    direction: rtl;
  }
  span.rev.phone:before {
    content: "3478-";
  }
  span.rev.phone:after {
    content:"002(614)";
  }

  span.rev.email:before {
    content: "sU liamE";
  }

  .table {
    border-radius: 4px;
  }
</style>
